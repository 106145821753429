import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';

import { Tumbler } from './Tumbler';
import { Icon } from './Icon';
import { Tooltip } from './Tooltip';
import { isEqual } from '../utils/compare';
import {
  moveItem,
  filterItems,
  getItemClasses,
  updateStateOfOptions,
  makeStateOfOptions,
  traverseState,
  normalizeValue,
} from './select/helpers';
import { SortableContainer } from './select/SortableContainer';
import { SortableItem } from './select/SortableItem';
import { WithErrorPlaceholderImage } from './select/WithErrorPlaceholderImage';
import { Button } from './Button';

import '../scss/_select.scss';

const PADDING_STEP = 20;

const BtnWrapper = ({ children, className, tooltipParams, handleClick }) => {
  return tooltipParams
    ? (
      <Tooltip className={className} handleClick={handleClick} {...tooltipParams}>
        {children}
      </Tooltip>
    )
    : (
      <div className={className} onClick={handleClick}>
        {children}
      </div>
    );
};

export class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      search: '',
      tumblerIsActive: props.tumblerIsActive,
      withGroups: props.options.some(item => item.isGroupHeader),
      stateOfOptions: makeStateOfOptions(props.options, props.value, props.dnd),
      isSorting: false,
      tabsModeIsInclude: true,
    };
  }

  _selectList = null;

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual([
      'value',
      'options',
      'isMulti',
      'multiPlaceholder',
      'allSelectable',
      'isSearchable',
      'hideValueOnFocus',
      'onChange',
      'showControlLabel',
      'tumbler',
      'dnd',
      'onTumblerChange',
      'label',
      'labelImageSrc',
      'reactLabel',
      'placeholder',
      'className',
      'isOpen', // sets isOpen state through prop
      'toggleOpenProp', // changes isOpen prop value
      'ignoredCloseClickElement', // prevents select from closing when clicking on outer element that changes isOpen prop
    ], this.props, nextProps) && isEqual([
      'isOpen',
      'search',
      'stateOfOptions',
      'tumblerIsActive',
      'targetDropIndex',
      'startDragIndex',
      'isSorting',
      'tabsModeIsInclude',
    ], this.state, nextState));
  }

  componentDidMount() {
    window.addEventListener('click', this.windowClickHandler);
    this.setOpenStateThroughProp();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(['value', 'options', 'isMulti', 'dnd'], this.props, prevProps) ||
      !isEqual(['isSorting'], this.state, prevState)) {
      this.setState({
        withGroups: this.props.options.some(item => item.isGroupHeader),
        stateOfOptions: makeStateOfOptions(this.props.options, this.props.value, this.props.dnd),
      });
    }
    if (prevProps.tumblerIsActive !== this.props.tumblerIsActive) {
      this.setState({ tumblerIsActive: this.props.tumblerIsActive });
    }
    if (this.state.isOpen && (this.state.isOpen !== prevState.isOpen)) {
      if (this._selectList) {
        this._selectList.classList.add(this.props.scrollableClassName || '_scrollable');
      }
      this.searchInput && this.searchInput.focus(); // has isOpen state changed to true, then focus search input
    }

    // If state.isOpen was changed, then props.isOpen is not yet updated here.
    // This function should be fired by props.isOpen change.
    if (prevState.isOpen === this.state.isOpen) {
      this.setOpenStateThroughProp(prevProps);
    }

    // clear filter after drop-down was closed
    if (!this.state.isOpen && prevState.isOpen) {
      this.setState({ search: '' });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowClickHandler);
  }

  // opens/closes select clicking on external element
  setOpenStateThroughProp = (prevProps) => {
    // if isOpen prop is set and not equal to isOpen state
    if (typeof this.props.isOpen === 'boolean' && this.props.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
      // prevents closing through windowClickHandler when clicking on external el that changes isOpen prop
      this.ignoredCloseClickElement = this.props.ignoredCloseClickElement;
    }
  }

  windowClickHandler = ({ target }) => {
    if (!this.state.isOpen || target.closest('.js-select') === this.select ||
      // prevent closing if el added as ignored
      (this.ignoredCloseClickElement && target === this.ignoredCloseClickElement)
    ) {
      return;
    }
    this.setState({ isOpen: false });
    // changes isOpen prop when isOpen state is changed
    this.props.toggleOpenProp && this.props.toggleOpenProp(false);
  }

  toggleSelect = () => {
    this.setState({ isOpen: !this.state.isOpen });
    // changes isOpen prop when isOpen state is changed
    this.props.toggleOpenProp && this.props.toggleOpenProp(!this.state.isOpen);
  }

  setStateOptions(state, item, fromDeselectedAll) {
    if (this.props.onChange) {
      let values = [];
      const callback = (item) => {
        if (item.isActive) values.push(item);
      };
      traverseState(state, callback);
      if (!this.props.isMulti) {
        values = values.length ? values[0] : null;
      }
      // add fromDeselectedAll for lazyLoad select
      this.props.onChange(normalizeValue(values), item, fromDeselectedAll);
    }
    this.setState({ stateOfOptions: state });
  }

  handleChangeSearch = ({ target }) => {
    this.setState({ search: target.value });
  }

  toggleTumbler = () => {
    this.props.onTumblerChange();
  }

  handleSelectedOption = (item) => (e) => {
    const { isMulti, withTabs } = this.props;
    const { stateOfOptions, tabsModeIsInclude } = this.state;

    if (withTabs) {
      if (!item.isActive) {
        item = Object.assign(item, tabsModeIsInclude ? { included: true } : { excluded: true });
      }
    }

    const newState = updateStateOfOptions(stateOfOptions, item, isMulti);
    this.setStateOptions(newState, item);

    if (!isMulti) {
      this.toggleSelect();
    }
  }

  allSelected = () => {
    const { totalRecords, searchByValue } = this.props;
    const { stateOfOptions, search } = this.state;
    if (!stateOfOptions.length) {
      return false;
    }
    let allSelected = true;
    const callback = (item) => {
      if (!item.isActive) allSelected = false;
    };
    traverseState(filterItems(stateOfOptions, search, searchByValue), callback);
    return totalRecords
      ? allSelected && stateOfOptions.length === totalRecords
      : allSelected;
  }

  deselectAll = () => {
    const { stateOfOptions } = this.state;
    const cloneState = cloneDeep(stateOfOptions);
    const callback = (item) => {
      if (!item.alwaysEnabled) {
        item.isActive = false;
      }
    };
    traverseState(cloneState, callback);
    this.setStateOptions(cloneState, null, true);
  }

  selectAll = () => {
    const { searchByValue, onSelectAll, withTabs } = this.props;
    const { stateOfOptions, search, tabsModeIsInclude } = this.state;
    const cloneState = filterItems(cloneDeep(stateOfOptions), search, searchByValue);
    const callback = (item) => {
      item.isActive = true;
      if (withTabs) {
        Object.assign(item, tabsModeIsInclude ? { included: true } : { excluded: true });
      }
    };
    traverseState(cloneState, callback);
    this.setStateOptions(cloneState);
    if (onSelectAll) {
      onSelectAll();
    }
  }

  onSortStart = () => {
    this.setState({ isSorting: true });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const newValues = moveItem(this.state.stateOfOptions, oldIndex, newIndex);
    this.setStateOptions(newValues);
    this.setState({
      targetDropIndex: null,
      startDragIndex: null,
      isSorting: false,
    });
  };

  handleClearValue = (e) => {
    e.stopPropagation();
    this.deselectAll();
  }

  renderItem = (item, i) => {
    const { isMulti, levelPadding, dnd, withTabs } = this.props;
    const { targetDropIndex, startDragIndex, tabsModeIsInclude } = this.state;

    let additionalProps = {
      style: {},
    };
    const padd = (item.deep || 0) * (levelPadding || PADDING_STEP);
    if (padd !== 0) {
      additionalProps.style.paddingLeft = padd;
    }

    if (!item.isGroupHeader && !item.alwaysEnabled) {
      additionalProps.onClick = this.handleSelectedOption(item);
    }

    // to make clickable group header bold the checking is needed:
    // - if option is not a child of other option and have its own children
    // - and also if no reactLabel is used since it has its own markup
    const isClickableGroupHeader = !item.reactLabel && (item.deep === 0) && item.options && item.options.length;

    const { isActive, draggableGroup, lastFirstGroup, included, excluded } = item;
    let isDisable = false;

    if (withTabs) {
      isDisable = tabsModeIsInclude ? !!excluded : !!included;
    }

    let className = getItemClasses(item, { isActive, isDisable });

    if (dnd && isActive && isMulti) {
      const indexItem = i;
      className = getItemClasses(item, { isActive, indexItem, startDragIndex, targetDropIndex });
    }

    if (isClickableGroupHeader) {
      className += ' _clickable-header';
    }

    let separator = lastFirstGroup ? <div className="_separator" /> : null;

    return dnd
      ? (
        <React.Fragment key={item.label ? item.label : i}>
          <SortableItem
            index={i}
            item={item}
            isActive={isActive}
            disabled={!isActive}
            collection={draggableGroup ? `${draggableGroup}` : isActive ? 'active' : 'disabled'}
            className={className}
            {...additionalProps} />
          {separator}
        </React.Fragment>
      )
      : (
        <div
          key={item.value !== undefined ? item.value : i}
          className={className}
          {...additionalProps}
        >
          <div className="select__option-label">
            {item.labelImageSrc &&
              <div className="select__option-image">
                <WithErrorPlaceholderImage src={item.labelImageSrc} />
              </div>
            }
            {item.reactLabel || item.label}
          </div>
        </div>
      );
  }

  renderList = () => {
    const { isSearchable, searchByValue, dnd } = this.props;
    const { search, stateOfOptions } = this.state;
    if (!stateOfOptions) {
      return null;
    }

    let resultOptions = stateOfOptions;
    if (isSearchable) {
      resultOptions = filterItems(stateOfOptions, search, searchByValue);
    }

    const res = [];
    const cb = (item) => res.push(item);
    traverseState(resultOptions, cb);
    return dnd
      ? (
        <SortableContainer
          helperClass="select__dragged _active"
          distance={5}
          onSortStart={this.onSortStart}
          onSortEnd={this.onSortEnd}
          onAttachedRef={el => {
            this._selectList = el;
          }}
          items={res}>
          {res.map((item, i) => this.renderItem(item, i))}
        </SortableContainer>
      )
      : (
        <div className={`select__list`} ref={el => { this._selectList = el; }}>
          {res.map((item, i) => this.renderItem(item, i))}
        </div>
      );
  }

  renderButton = () => {
    const { buttonProps } = this.props;
    if (buttonProps) {
      return (
        <div className="w-100 d-flex justify-content-center">
          <Button className={buttonProps.className || 'btn-round _conflower-blue mt-3 mb-3'} onClick={buttonProps.onClick}>
            {!!buttonProps.symbolBeforeLabel && (
              <span className="btn-round__prefix">{buttonProps.symbolBeforeLabel}</span>
            )}
            {buttonProps.label || 'New'}
          </Button>
        </div>
      );
    }
    return null;
  }

  renderSelectedValue = () => {
    const { isMulti, multiPlaceholder, allSelectedText, value: v } = this.props;
    const { isOpen } = this.state;
    if (isMulti) {
      if (!isOpen && this.allSelected()) {
        return allSelectedText || 'All Selected';
      }
      return (v && !!v.length && (multiPlaceholder ? multiPlaceholder(v.length) : `${v.length} Selected`));
    } else {
      return (v && (v.reactLabel || v.label));
    }
  }

  renderTabs = () => {
    const { value } = this.props;
    const { tabsModeIsInclude } = this.state;
    return (
      <div className="select__tabs d-flex mb-3">
        <div
          className={`tab flex-grow-1 text-center ${tabsModeIsInclude ? '_active' : ''}`}
          onClick={() => this.setState({ tabsModeIsInclude: true })}>
          {`Include (${Array.isArray(value) ? this.props.value.filter(i => i.included).length : 0})`}
        </div>
        <div
          className={`tab flex-grow-1 text-center ${tabsModeIsInclude ? '' : '_active'}`}
          onClick={() => this.setState({ tabsModeIsInclude: false })}>
          {`Exclude (${Array.isArray(value) ? this.props.value.filter(i => i.excluded).length : 0})`}
        </div>
      </div>
    );
  }

  render() {
    const {
      className,
      label,
      reactLabel,
      allSelectable,
      isMulti,
      isClearable,
      hideCrossIfClosed,
      hideValueOnFocus,
      selectAllLabel,
      tumbler,
      tumblerLabel,
      dnd,
      value,
      isSearchable,
      changeSearchHandler,
      placeholder = 'Select...',
      tooltipParams,
      withTabs,
    } = this.props;
    const { isOpen, search, tumblerIsActive, withGroups, isSorting } = this.state;

    const isSelected = value && (value.reactLabel || value.label || value.length);

    let labelClassName = 'select__label';
    if (hideValueOnFocus) {
      if (!isOpen) {
        if (isSelected) {
          labelClassName += ' _up';
        }
      }
    } else {
      if (isSelected) {
        labelClassName += ' _up';
      }
    }

    let showLabel = true;
    if (!reactLabel && !label) {
      if (!(hideValueOnFocus && isOpen) && isSelected) {
        showLabel = false;
      }
    }

    return (
      <div
        className={`select js-select ${isSorting ? '_sorting' : ''} ${isOpen ? '_opened' : ''} ${withGroups ? '_with-groups' : ''} ${className || ''} ${dnd ? '_dnd' : ''}`}
        ref={el => { this.select = el; }}
      >
        <div className="select__outer-wrapper">
          <div className="select__wrapper">
            <BtnWrapper className="select__btn" handleClick={this.toggleSelect} tooltipParams={tooltipParams}>
              {showLabel &&
                <div className={labelClassName}>
                  {(hideValueOnFocus && isOpen)
                    ? (
                      reactLabel || label || placeholder
                    )
                    : (
                      isSelected ? (reactLabel || label) : placeholder
                    )}
                </div>
              }
              <div className={`select__value ${hideValueOnFocus ? '_bold' : ''} ${isSelected ? '_selected' : ''}`}>
                {(!hideValueOnFocus || !isOpen) && this.renderSelectedValue()}
              </div>
              {isMulti && isOpen && allSelectable && !this.allSelected() &&
                <div
                  className="select__btn-secondary"
                  onClick={e => {
                    e.stopPropagation();
                    this.selectAll();
                  }}
                >
                  {selectAllLabel || 'Select All'}
                </div>
              }
              {
                (isClearable || allSelectable) &&
                (!hideCrossIfClosed || isOpen) &&
                value &&
                (!Array.isArray(value) || !!value.length) &&
                <div className="select__clear" onClick={this.handleClearValue}>
                  <Icon name="Close" />
                </div>
              }
              <div className="select__indicator" />
            </BtnWrapper>
            {isOpen &&
              <div className="select__list-wrapper">
                {withTabs && isMulti && this.renderTabs()}
                {isSearchable &&
                  <div className="select__list-input-wrapper">
                    <Icon name="Search" />
                    <input
                      ref={el => { this.searchInput = el; }}
                      placeholder="Search"
                      className="select__list-input"
                      value={changeSearchHandler ? undefined : search}
                      onChange={changeSearchHandler || this.handleChangeSearch}
                    />
                  </div>
                }
                {this.renderButton()}
                {tumbler &&
                  <Tumbler
                    className="select__tumbler"
                    onChange={this.toggleTumbler}
                    on={tumblerIsActive}
                    title={tumblerLabel || 'Show groups'}
                  />
                }
                {this.renderList()}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

BtnWrapper.propTypes = {
  className: PropTypes.string,
  tooltipParams: PropTypes.object,
  handleClick: PropTypes.func,
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  tumblerIsActive: PropTypes.bool,
  multiPlaceholder: PropTypes.func,
  allSelectable: PropTypes.bool,
  isClearable: PropTypes.bool,
  hideCrossIfClosed: PropTypes.bool,
  hideValueOnFocus: PropTypes.bool,
  tumbler: PropTypes.bool,
  dnd: PropTypes.bool,
  onTumblerChange: PropTypes.func,
  label: PropTypes.string,
  reactLabel: PropTypes.elementType,
  labelImageSrc: PropTypes.string,
  placeholder: PropTypes.string,
  selectAllLabel: PropTypes.string,
  tumblerLabel: PropTypes.string,
  isSearchable: PropTypes.bool,
  searchByValue: PropTypes.bool,
  className: PropTypes.string,
  levelPadding: PropTypes.number,
  totalRecords: PropTypes.number,
  changeSearchHandler: PropTypes.func,
  allSelectedText: PropTypes.string,
  scrollableClassName: PropTypes.string,
  tooltipParams: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleOpenProp: PropTypes.func,
  ignoredCloseClickElement: PropTypes.object,
  buttonProps: PropTypes.exact({
    label: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    symbolBeforeLabel: PropTypes.string,
  }),
  onSelectAll: PropTypes.func,
  withTabs: PropTypes.bool,
};
