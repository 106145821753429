import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { ButtonCircle } from './Button';
import { Snackbar } from './Snackbar';

import styles from './CopyToClipboardButton/CopyToClipboardButton.module.scss';

export class CopyToClipboardButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowSnackbarOpen: false,
    };
  }

  handleCloseSnackbar = () => {
    this.setState({ isShowSnackbarOpen: false });
  };

  onCopy = (code) => {
    const input = document.createElement('textarea');
    input.value = code;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    this.setState({
      isShowSnackbarOpen: true,
    });
  };

  handleButtonClick = () => this.onCopy(this.props.code)

  render() {
    const { className = '' } = this.props;
    const { isShowSnackbarOpen } = this.state;

    return (
      <React.Fragment>
        <ButtonCircle
          className={`_size-20 ${styles.btn} ${className}`}
          onClick={this.handleButtonClick}
          iconName="Copy"
          outline
        />
        {createPortal(
          <Snackbar
            open={isShowSnackbarOpen}
            onClose={this.handleCloseSnackbar}
            message="Copied!"
            autoHideDuration={2000}
            action={[
              <button
                key="close"
                onClick={this.handleCloseSnackbar}
                className={styles.close}
              >
                Close
              </button>
            ]}
          />,
          document.body
        )}
      </React.Fragment>
    );
  }
}

CopyToClipboardButton.propTypes = {
  className: PropTypes.string,
  code: PropTypes.string,
};
