import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_tumbler.scss';

export class Tumbler extends Component {
  render() {
    const {
      onOff = false,
      className,
      on = false,
      onChange,
      disabled,
      title,
    } = this.props;

    const classNames = ['tumbler'];
    if (disabled) {
      classNames.push('_disabled');
    }
    if (onOff) {
      classNames.push('_on-off');
    }
    if (on) {
      classNames.push('_on');
    }
    if (className) {
      classNames.push(className);
    }

    return (
      <div className={classNames.join(' ')} onClick={onChange}>
        <div className="tumbler__label">
          <span className="tumbler__btn">
            {onOff && <React.Fragment>
              <span className="tumbler__bg">
                <i>off</i>
                <i>on</i>
              </span>
              <span className="tumbler__control">
                <i>off</i>
                <i>on</i>
              </span>
            </React.Fragment>}
          </span>

          {title &&
            <span className="tumbler__title">
              {title}
            </span>
          }
        </div>
      </div>
    )
  }
}

Tumbler.propTypes = {
  onOff: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  on: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};
