import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontFaceObserver from 'fontfaceobserver';
import '../scss/_switch.scss';
import { isEqual } from '../utils/compare';

export class Switch extends Component {
  constructor(props) {
    super(props);

    this.switchItems = [];
    this.size = props.size;
    this.state = {
      itemsMaxWidth: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      isEqual([
        'list',
        'className',
        'value',
        'name',
        'size',
      ], this.props, nextProps) &&
      isEqual(['itemsMaxWidth'], this.state, nextState)
    );
  }

  componentDidMount() {
    this.makeItemsWidthsEqual();
    this.forceUpdate();
    this.onFontLoaded();
  }

  componentDidUpdate() {
    const { itemsMaxWidth } = this.state;
    if (this.size !== this.props.size) {
      this.size = this.props.size;
      this.setState(
        {itemsMaxWidth: null},
        this.makeItemsWidthsEqual(),
      );
    }
    if (!itemsMaxWidth) {
      this.makeItemsWidthsEqual();
    }
  }

  onFontLoaded = () => {
    const font = new FontFaceObserver('Lato Regular');

    font.load().then(() => {
      this.setState({itemsMaxWidth: null});
    });
  }

  makeItemsWidthsEqual = () => {
    const max = this.getMax();
    if (max) {
      this.setState({itemsMaxWidth: max});
    }
  }

  getMax = () => {
    return this.switchItems.length && Math.max(...this.switchItems.map((item) => item.offsetWidth));
  }

  _pushSwitchItemRef(item) {
    if (item && this.switchItems.indexOf(item) === -1) {
      this.switchItems.push(item);
    }
  }

  _setIndicatorRef(item) {
    if (!this.indicator) {
      this.indicator = item;
    }
  }

  handleClick = () => {
    const { value, onChange } = this.props;
    onChange(!value);
  }

  render() {
    const {
      size,
      className = '',
      list,
      value = false,
    } = this.props;

    const { itemsMaxWidth } = this.state;

    const indicatorStyle = {
      left: `${value ? (itemsMaxWidth - 1) : -1}px`,
      width: `${itemsMaxWidth + 2}px`,
    };

    return (
      <div
        className={`switch ${className} ${size ? `_${size}` : ''}`}
        ref={el => { this.switch = el; }}
        onClick={this.handleClick}
      >
        <div className="switch__label">
          {list.map((item, i) => {
            return (
              <div
                key={i}
                className={`switch__item ${(value === Boolean(i)) ? '_active' : ''}`}
                ref={(item) => this._pushSwitchItemRef(item)}
                style={{ minWidth: itemsMaxWidth || '' }}
              >
                {item}
              </div>
            )
          })}
          <div
            className="switch__indicator"
            ref={(item) => this._setIndicatorRef(item)}
            style={indicatorStyle}
          />
        </div>
      </div>
    );
  }
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.bool.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
};
