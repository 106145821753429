import { cloneDeep } from 'lodash';

const isIncludeSubstr = (a, b) => a.toLowerCase().includes(b.toLowerCase());

const itemIsSelected = (item, selectedValue) => {
  if ((selectedValue === null || selectedValue === undefined) ||
    (Array.isArray(selectedValue) && !selectedValue.length)) {
    return false;
  }
  if (Array.isArray(selectedValue)) {
    return selectedValue.some(v => v.value === item.value);
  }
  return item.value === selectedValue.value;
};

export const moveItem = (a, from, to) => {
  if (from === to || to === null) return a;
  const clone = [...a];
  clone.splice(to, 0, clone.splice(from, 1)[0]);
  return clone;
};

export const filterItems = (list, search, searchByValue) => {
  const recurse = (arr) => {
    return arr
      .map(item => {
        if (Array.isArray(item.options)) {
          const options = recurse(item.options);
          if (options && options.filter(o => o).length) {
            return {
              ...item,
              options
            };
          }
        }
        return isIncludeSubstr(item.label, search) ||
          (searchByValue && item.value.toString().includes(search)) ? item : null;
      })
      .filter(o => o);
  };

  return recurse(list);
};

export const getItemClasses = (item, { isActive, indexItem, startDragIndex, targetDropIndex, isDisable }) => {
  let className = 'select__option';
  if (isActive) className += ' _active';
  if (item.isGroupHeader) className += ' _header';
  if (item.alwaysEnabled) className += ' _enabled';
  if (item.lastFirstGroup) className += ' _last-enabled';
  if (isDisable) className += ' _disabled';

  if (indexItem !== undefined) {
    if (targetDropIndex === indexItem) className += ' _separator';
    if (startDragIndex === indexItem) className += ' _hide';
    className += startDragIndex < targetDropIndex ? ' _bottom' : ' _top';
  }

  return className;
};

export const updateStateOfOptions = (state, item, isMulti) => {
  const traverseMulti = (list, parentSelected) => {
    if (!list) return;

    list.forEach(node => {
      let isActive, included, excluded;
      if (node.value === item.value) {
        isActive = !node.isActive;
        if (node.isActive) {
          delete node.included;
          delete node.excluded;
        } else {
          included = !!item.included;
          excluded = !!item.excluded;
        }
      } else if (parentSelected !== undefined) {
        isActive = parentSelected;
      }

      if (isActive !== undefined) {
        node.isActive = isActive;
        if (included) {
          node.included = included;
        }
        if (excluded) {
          node.excluded = excluded;
        }
      }

      if (!(node.options && node.options.length)) return;

      traverseMulti(node.options, isActive);
      node.isActive = node.options.every(o => o.isActive);
    });
  };

  const traverseSingle = (list) => {
    if (!list) return;

    list.forEach(node => {
      node.isActive = item.value === node.value;
      traverseSingle(node.options);
    });
  };

  const traverse = (list) => {
    if (!list) return;
    isMulti ? traverseMulti(list) : traverseSingle(list);
  };
  const cloneState = cloneDeep(state);
  traverse(cloneState);
  return cloneState;
};

const FIRST_GROUP_ID = 1;

export const makeStateOfOptions = (options, selectedValue, dnd = false) => {
  const recurse = (list, deep = 0) => {
    return list.map(item => {
      let res = {
        ...item,
        deep,
        isActive: item.alwaysEnabled || itemIsSelected(item, selectedValue),
      };
      if (item.options && item.options.length) {
        res.options = recurse(item.options, deep + 1);
      }
      if (item.alwaysEnabled) {
        res.alwaysEnabled = item.alwaysEnabled;
      }
      if (Array.isArray(selectedValue) && selectedValue.length) {
        const foundedItem = selectedValue.find(v => v.value === item.value);
        if (foundedItem) {
          if (foundedItem.included) {
            res.included = true;
          }
          if (foundedItem.excluded) {
            res.excluded = true;
          }
        }
      }
      return res;
    });
  };

  let state = recurse(options);
  if (dnd) {
    let selected = Array.isArray(selectedValue) ? selectedValue : [];
    const selectedValuesOrder = selected.reduce((acc, v) => {
      if (options.some(o => o.value === v.value)) {
        const item = state.find(s => s.value === v.value);
        if (item) acc = [...acc, item];
      }
      return acc;
    }, []);
    const fixedOptions = state.filter(o => o.alwaysEnabled);
    let res = [
      ...fixedOptions.filter(o => !selectedValuesOrder.some(s => o.value === s.value)),
      ...selectedValuesOrder,
    ];
    const numberOfFirstGroup = res.filter(v => v.draggableGroup === 1).length;
    res = res.filter(v => v.draggableGroup === FIRST_GROUP_ID).concat(res.filter(v => v.draggableGroup !== FIRST_GROUP_ID));
    if (numberOfFirstGroup) {
      res[numberOfFirstGroup - 1] = {
        ...res[numberOfFirstGroup - 1],
        lastFirstGroup: true
      };
    }
    state = [...res, ...state.filter(v => !v.isActive)];
  }

  return state;
};

export const traverseState = (list, cb) => {
  list.forEach(item => {
    cb(item);
    if (item.options && item.options.length) traverseState(item.options, cb);
  });
};

export const normalizeValue = (value) => {
  if (!value) return value;
  const getProps = ({ deep, options, isActive, ...rest }) => rest;
  if (!Array.isArray(value)) {
    return getProps(value);
  } else {
    const list = value.map(v => getProps(v));
    return list;
  }
};
